<template>
    <div>
        <b-breadcrumb class="mb-2">
            <b-breadcrumb-item to="/orders/recurring">Packages</b-breadcrumb-item>
            <b-breadcrumb-item>
                <router-link :to="{
                    name: 'commande',
                    params: { id: this.$route.query.packageId }

                }">Commandes</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
                <router-link :to="{
                    name: 'admin-orders-recurring-details',
                    params: { id: this.$route.params.id },
                    query: { packageId: this.$route.query.packageId, service: this.$route.query.service },

                }">Détails</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>Feedback</b-breadcrumb-item>
        </b-breadcrumb>
        <div v-if="isQuizLoading" class="d-flex justify-content-center mb-1" style="padding-top: 30vh">
            <b-spinner variant="info" style="width: 3rem; height: 3rem" class="mr-1" />
        </div>
        <!-- Order assignment Modal  -->
        <div v-else>
            <b-card title="Modifier un feedback">
                <b-row>
                    <b-col>
                        <validation-observer #default="{}" ref="orderAssignmentForm" tag="form">
                            <!-- Form -->
                            <b-form class="p-2" autocomplete="off" v-for="(form) in quiz" :key="index">
                                <h5>{{ form.question }}</h5>
                                <b-form-group label-for="textarea-default">
                                    <validation-provider #default="{ errors }" name="reponse" rules="required">
                                        <b-form-textarea id="textarea-default" v-model="form.answer"
                                            :state="errors.length > 0 ? false : null" placeholder="Entrez la réponse "
                                            rows="3" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-form>

                            <!-- Form Actions -->
                            <div class="d-flex mt-2" @click="applySendFeedbackAction()">
                                <b-button :disabled="isFeedbackLoading" variant="primary" class="mr-2">
                                    <div v-if="isFeedbackLoading">
                                        <span> Chargement ... </span>
                                        <b-spinner small />
                                    </div>
                                    <span v-else>Soumettre</span>
                                </b-button>
                            </div>
                        </validation-observer>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </div>
</template>
  
<script>
import {
    BRow,
    BCol,
    BTab,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BTableLite,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BSpinner,
    BFormSelect,
    BForm,
    BCard,
    BTabs,
    BFormGroup,
    BFormTextarea,
    BBreadcrumb,
    BBreadcrumbItem,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";

export default {
    components: {
        BSpinner,
        BTab,
        BRow,
        BCol,
        BFormInput,
        BCard,
        BTableLite,
        BButton,
        BTable,
        BMedia,
        BTabs,
        BDropdown,
        BDropdownItem,
        BAvatar,
        BBadge,
        BPagination,
        BTooltip,
        BFormSelect,
        BForm,
        BFormGroup,
        BFormTextarea,
        ValidationProvider,
        ValidationObserver,
        VueGoodTable,
        vSelect,
        BBreadcrumb,
        BBreadcrumbItem,
    },

    data() {
        return {
            /* eslint-disable global-require */
            feedbackIndex: "",
            getQuiz: [],
            isQuizLoading: false,
            quiz: [

            ],
            questions: [

            ],
            payload: {
                feedback: [],

            },
            isFeedbackLoading: false,
        }
    },

    computed: {
        ...mapGetters("orders", [
            "getDirectOrdersHaveNotProposal",
            "getDirectOrdersWhoNeedRecommandation",
        ]),
        ...mapGetters(["isDirectOrdersWhoNeedRecommandationLoading"]),
        ...mapGetters("auth", ["getUser"]),
        ...mapGetters("roles", ["getBusinessManagersList"]),


    },



    created() {
        this.applyGetFeedbackAnswers()


    },

    mounted() {
    },

    methods: {
        ...mapActions("services", ["getServiceEmployeesAction", "getQuizAction"]),
        ...mapActions("packages", [
            "getDirectOrderAction",
            "getDirectPackageOrdersAction",
            "sendFeedbackAction",
            "getFeedbackAnswersAction",
            "makePropositionAction",
            "getRecommendedEmployeesAction",
            "getDirectOrderProposedEmployeesAction",
            "getRecommendationEmployeesAction",
            "deleteDirectOrderPropositionAction",
            "getSalaryDetailsAction",
        ]),
        ...mapActions("orders", [
            "assignAnOrderToBusinessManagerAction",
            "loadMoreDirectOrdersWhoNeedRecommandationAction",
            "getDirectOrdersAssignedToBusinessManagerAction",
            "makeEmployeeRecommandationAction",
            "modifyRecommandationAction",
            "getRecommendedEmployeesAction",
            "getDirectOrderProposedEmployeesAction",
            "modifyRecommandationAction",
        ]),
        ...mapActions("professionals", ["deleteEmployeeRecommendationAction"]),

        applyGetQuiz() {
            /* recurring/services/serviceId/quiz */
            this.isQuizLoading = true

                .then(response => {

                    response.data.forEach((element) => {
                        this.questions.push({
                            quiz: element.id,
                            question: element.question,
                            answer: ""
                        });
                    });
                    this.isQuizLoading = false

                    console.log('QUIZ', this.quiz)

                })
                .catch(error => {
                    this.isQuizLoading = false
                    console.log(error)
                })
        },

        applyGetFeedbackAnswers() {
            this.isQuizLoading = true
            this.getFeedbackAnswersAction(this.$route.params.id)
                .then(answersResponse => {
                    this.getQuizAction(this.$route.query.service)
                        .then(response => {
                            this.isQuizLoading = false
                            console.log(response.data)
                            this.quiz = answersResponse.data
                            if (response.data.length !== this.quiz) {
                                for (let i = 0; i < response.data.length; i++) {
                                    let q = response.data[i];
                                    let el = this.quiz.find(element => element.quiz == q.id)
                                    if (el == undefined) {
                                        this.quiz.push({
                                            quiz: q.id,
                                            question: q.question,
                                            answer: ""
                                        })
                                    }
                                }

                            }

                        })
                })
                .catch(error => {
                    this.isQuizLoading = false
                    console.log(error)
                })
        },



        applySendFeedbackAction() {
            console.log('QUIZ STORE', this.quiz)
            this.isFeedbackLoading = true;
            if (this.getUser.role.slug === "super-administrateur") {
                this.payload.type = "CA"
            }
            this.payload.feedback = this.quiz

            this.sendFeedbackAction({
                orderId: this.$route.params.id,
                payload: this.payload,
            })
                .then(async (response) => {
                    this.isFeedbackLoading = false;

                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: response.message,
                                variant: "success",
                            },
                        },
                        {
                            position: "top-center",
                        }
                    )
                    this.$router.push({
                        path: '/preview/pdf',
                        query: {
                            makeActionOnPage: true,
                            actionType: 'modifier',
                            isUpdate: false,
                            pdf: response.feedbac_url,
                            orderId: response.data.id,
                            packageId: response.data.package_id,
                            service: response.data.recurring_service_id
                        },
                    })
                })
                .catch((error) => {
                    this.isFeedbackLoading = false;
                    console.log(error)
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error.response.data.message,
                                variant: "danger",
                            },
                        },
                        {
                            position: "top-center",
                        }
                    );
                });
        },


    },
};
</script>
  
<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
  